import React, { useEffect, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import { useAuth } from './context/AuthContext';

import './styles/App.css';
import SideBar from './components/SideBar';

function App() {
  const { refreshUser, user, logout } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(15); // 15 seconds for debug

  const startCountdown = useCallback(() => {
    setCountdown(15); // reset countdown for debug
    setShowModal(true);
  }, []);

  const handleLogout = useCallback(() => {
    setShowModal(false);
    logout();
    navigate('/login');
  }, [navigate, logout]);

  const handleStayLoggedIn = useCallback(() => {
    refreshUser().then((loggedIn) => {
      if (loggedIn) {
        setShowModal(false);
        setCountdown(15); // reset countdown
      } else {
        navigate('/login');
      }
    });
  }, [navigate, refreshUser]);

  useEffect(() => {
    refreshUser().then((loggedIn) => {
      if (!loggedIn) {
        navigate('/login');
      }
    });
  }, []); //eslint-disable-line

  // Start countdown before token expiry
  useEffect(() => {
    const interval = setInterval(() => {
      refreshUser().then((loggedIn) => {
        if (!loggedIn) {
          navigate('/login');
        }
      });
    }, 1000 * 60 * 10);

    const warningTimeout = setTimeout(() => {
      startCountdown();
    }, 1000 * 60 * 30);

    return () => {
      clearInterval(interval);
      clearTimeout(warningTimeout);
    };
  }, [navigate, startCountdown, refreshUser]);

  useEffect(() => {
    if (showModal && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      handleLogout();
    }
  }, [showModal, countdown, handleLogout]);

  return user ? (
    <div className="App">
      <SideBar />
      <div className="main-content">
        <Outlet />
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Session Timeout Warning</h2>
            <p>You will be logged out in {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60} seconds. Do you want to stay logged in?</p>
            <button onClick={handleStayLoggedIn}>Stay Logged In</button>
            <button onClick={handleLogout}>Logout</button>
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default App;
