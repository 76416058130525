import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';

const WIPQueue = () => {
  const [contracts, setContracts] = useState([]);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [client, setClient] = useState({})
  const [invoiceTexts, setInvoiceTexts] = useState([]);
  const [selectedInvoiceTextId, setSelectedInvoiceTextId] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [isClosingContract, setIsClosingContract] = useState(false);
  const { APP_URL, getCookie } = useAuth();
  const { success, error } = useNotify();
  const { formatCurrency } = useUtils();
  const { clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId !== null && clientId !== undefined) {
      fetch(`${APP_URL}/clients/${clientId}`, {
        headers: {
          'Accept': 'application/json',
        },
        credentials: 'include',
      })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(data => {
              setClient(data)
              const openContracts = data.contracts.filter(contract => contract.open);
              setContracts(openContracts);
            });
          } else {
            error(resp);
          }
        })
        .catch(e => error(e));
    }
  }, [clientId, APP_URL, error]);

  useEffect(() => {
    fetch(`${APP_URL}/invoice_texts`, {
      credentials: 'include'
    })
      .then(resp => {
        if (resp.ok) {
          resp.json().then(setInvoiceTexts);
        } else {
          error(resp);
        }
      })
      .catch(e => error(e));
  }, [APP_URL, error]);

  const handleContractSelect = (contract) => {
    setSelectedContractId(contract.id);
  };

  const handleOpenModal = (isClosing = false) => {
    if (!selectedContractId) {
      error('Please select a contract first');
      return;
    }
    setIsClosingContract(isClosing);
    setShowModal(true);
  };

  const handleSubmit = () => {
    if (!selectedInvoiceTextId || (!totalAmount && !isClosingContract)) {
      error('Please fill in all fields');
      return;
    }

    const payload = {
      contract_id: selectedContractId,
      invoice_text_id: selectedInvoiceTextId,
      total: totalAmount,
    };

    if (isClosingContract) {
      payload.close = true;
      payload.total = 1;
    }

    fetch(`${APP_URL}/invoices`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(resp => {
        if (resp.ok) {
          const action = isClosingContract ? 'Contract closed and invoice generated' : 'Invoice generated';
          success(`${action} for Contract ID: ${selectedContractId}`);
          setShowModal(false);
          if (isClosingContract) {
            setContracts(contracts.filter(contract => contract.id !== selectedContractId));
          }
          resp.json().then(data => navigate(`/invoices/${data.success}`));
        } else {
          error(resp);
        }
      })
      .catch(e => error(e));
  };

  const invoiceTextOptions = invoiceTexts.map(text => ({
    value: text.id,
    label: text.name,
  }));

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-6">Open Contracts For {client.legal_name}</h2>
      <div className="overflow-auto mb-6">
        <table className="min-w-full table-auto border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="p-3 text-left bg-gray-100 border border-gray-300">Contract ID</th>
              <th className="p-3 text-right bg-gray-100 border border-gray-300">Remaining WIP</th>
            </tr>
          </thead>
          <tbody>
            {contracts.length === 0 ? (
              <tr>
                <td colSpan="2" className="text-center p-6 text-gray-500">No open contracts available</td>
              </tr>
            ) : (
              contracts.map((contract) => (
                <tr
                  key={contract.id}
                  className={`cursor-pointer hover:bg-blue-50 ${selectedContractId === contract.id ? 'bg-blue-100' : ''}`}
                  onClick={() => handleContractSelect(contract)}
                >
                  <td className="p-3 border border-gray-300">{contract.id}</td>
                  <td className="p-3 text-right border border-gray-300">{formatCurrency(contract.remaining_total)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center space-x-4 mb-6">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg disabled:opacity-50"
          onClick={() => handleOpenModal(false)}
          disabled={!selectedContractId}
        >
          Create Invoice
        </button>
        <button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-lg disabled:opacity-50"
          onClick={() => handleOpenModal(true)}
          disabled={!selectedContractId}
        >
          Close Contract
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <div className="mb-4">
              <h5 className="text-xl font-semibold mb-2">
                {isClosingContract ? 'Close Contract' : 'Create Invoice'}
              </h5>
              <button
                type="button"
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={() => setShowModal(false)}
              >
                &times;
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Invoice Text</label>
              <Select
                options={invoiceTextOptions}
                value={invoiceTextOptions.find(option => option.value === selectedInvoiceTextId)}
                onChange={selectedOption => setSelectedInvoiceTextId(selectedOption.value)}
              />
            </div>
            {!isClosingContract && (
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Total Amount</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  placeholder="Enter total amount"
                />
              </div>
            )}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-lg"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg"
                onClick={handleSubmit}
              >
                {isClosingContract ? 'Close Contract and Create Invoice' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WIPQueue;
