import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
// import { date } from 'yup';
import { useNotify } from '../context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useUtils } from '../context/UtilContext';

const SettingsPage = () => {
  const { logout } = useAuth();
  const {error, success} = useNotify()
  const {isFormattedDatesEnabled,setIsFormattedDatesEnabled} = useUtils()
  const navigate = useNavigate()
  const [formattedDates, setFormattedDates] = useState(false);

  const datesOption = localStorage.getItem('formattedDates') === 'true'

  const handleLogout = () => {
    logout().then(data => {
      if (data && !(data.hasOwnProperty('error'))) {
        success('Logged out!');
        navigate('/login');
      } else {
        error(data);
      }
    });
  };

  useEffect(()=>{
    if(datesOption){
        setFormattedDates(datesOption)
    }
  }, []) //eslint-disable-line

  const handleFormattedDatesChange = () => {
    localStorage.setItem('formattedDates',!formattedDates)
    setFormattedDates(!formattedDates);
    setIsFormattedDatesEnabled(!formattedDates)
  };

  return (
    <div className={`settings-page container mx-auto p-4 mt-8 bg-white rounded shadow`}>
      <h2 className="text-2xl font-bold mb-4">Settings</h2>
      
      {/* <div className="settings-item mb-4">
        <label className="form-label">Dark Mode:</label>
        <input
          type="checkbox"
          checked={isDarkMode}
          onChange={toggleDarkMode}
          className="ml-2"
        />
      </div> */}
      
      <div className="settings-item mb-4">
        <label className="form-label">Long Format Dates:</label>
        <input
          type="checkbox"
          checked={isFormattedDatesEnabled}
          onChange={handleFormattedDatesChange}
          className="ml-2"
        />
        
      </div>
      
      <button 
        className="btn btn-danger mt-4"
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default SettingsPage;
