import { createContext, useContext, useState } from 'react';

const UtilContext = createContext();

export const useUtils = () => {
    return useContext(UtilContext);
};

export const UtilsProvider = ({ children }) => {
    //Context to share needed functions and variables for misc use
    const formattedDates = localStorage.getItem('formattedDates') === 'true'

    const [isFormattedDatesEnabled, setIsFormattedDatesEnabled] = useState(formattedDates)

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,

        }).format(amount)
    };

    const formatDate = dateStr => {
        if (!dateStr) return '';
        
        let options;
        if(formattedDates){
            options = { year: 'numeric', month: 'long', day: 'numeric' };
        }else{
            options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        }
        
        return new Date(dateStr).toLocaleDateString(undefined, options);
    };
    

    const sum = (iter, key, initial=0) =>{
        return iter ? iter.reduce((accumulator,currentValue) =>{
            return accumulator + (key ? currentValue[key] : currentValue)
        },initial) : initial
    }

    
    

    return (
        <UtilContext.Provider value={{ formatCurrency, formatDate, sum, isFormattedDatesEnabled, setIsFormattedDatesEnabled}}>
            {children}
        </UtilContext.Provider>
    );
};
