import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useNotify } from "../context/NotificationContext";

const SideBar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { logout } = useAuth();
    const { success, error } = useNotify();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout().then((data) => {
            if (data && !data.hasOwnProperty("error")) {
                success("Logged out!");
                navigate("/login");
            } else {
                error(data);
            }
        });
    };

    return (
        <aside className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
            <button onClick={() => setIsCollapsed(!isCollapsed)} className="collapse-btn">
                {isCollapsed ? ">" : "<"}
            </button>
            {!isCollapsed && (
                <>
                    <h2>Dashboard</h2>
                    <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/profile">Time Entries</NavLink></li>
                        <li><NavLink to="/admin">Admin</NavLink></li>
                        <li><NavLink to="/settings">Settings</NavLink></li>
                        <li><NavLink to="/login" onClick={handleLogout}>Logout</NavLink></li>
                    </ul>
                </>
            )}
        </aside>
    );
};

export default SideBar;
