import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import TimeEntryForm from "./pages/TimeEntryForm";
import Client from "./pages/Client";
import Dashboard from "./pages/Dashboard";
import ClientForm from "./pages/ClientForm";
import UserForm from "./pages/UserForm";
import LoginPage from "./pages/LoginPage";
import UserManagement from "./pages/UserManagement";
import ClientManagement from "./pages/ClientManagement";
import AdminPage from "./pages/AdminPage";
import InvoicePage from "./pages/Invoice";
import ActivityTypesPage from "./pages/ActivityTypesPage";
import AccessDenied from "./pages/AccessDenied";
import WorkInProgressQueue from "./pages/WIPQueue";
import ContractForm from "./pages/ContractForm";
import ContractInfo from "./pages/ContractInfo";
import InvoicePaymentPage from "./pages/InvoicePayment";
import ProtectedRoute from "./components/ProtectedRoute";
import ContractSummary from "./pages/ContractSummary";
import Timesheet from "./pages/Timesheet";
import ClientActivities from "./pages/ClientActivities";
import Billing from "./pages/Billing";
import MyTimeEntries from "./pages/TimeEntries";
import InvoiceTextForm from "./pages/InvoiceTextForm";
import InvoiceTextManagement from "./pages/InvoiceTextManagement";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";

const clientRoutes = [
    {
        path: '/',
        element: <Dashboard />
    },
    {
        path: '/clients/:id',
        element: <Client />
    },
    {
        path: '/clients/create',
        element:
        <ProtectedRoute>
            <ClientForm />
        </ProtectedRoute>
    },
    {
        path: '/clients/:id/edit',
        element: 
         <ProtectedRoute>
           <ClientForm />
        </ProtectedRoute>
    },
    {
        path: '/clients/manage',
        element: 
        <ProtectedRoute>
           <ClientManagement />
        </ProtectedRoute>
    },
    {
        path: '/clients/:clientId/wip',
        element: <WorkInProgressQueue />
    },
    {
        path: '/clients/:id/activity-types',
        element:
        <ProtectedRoute>
            <ClientActivities/>
        </ProtectedRoute>

    },


];

const timeEntryRoutes = [
    {
        path: '/time_entries/:id/edit',
        element: <TimeEntryForm />
    },
    {
        path: '/time_entries/create',
        element: <TimeEntryForm />
        
    }
];


const userRoutes = [
    {
        path: '/users/create',
        element: 
        <ProtectedRoute>
            <UserForm />
        </ProtectedRoute>
        
    },
    {
        path: '/users/manage',
        element: 
        <ProtectedRoute>
            <UserManagement />
        </ProtectedRoute>
        
    },
    {
        path: '/users/:userId/edit',
        element: 
        <ProtectedRoute>
            <UserForm />
        </ProtectedRoute>
       
    },
    {
        path: '/profile',
        element: <MyTimeEntries />
    },
    {
        path: '/settings',
        element: <SettingsPage/>
    }
];



const contractRoutes = [
    {
        path: '/contracts/manage',
        element:
            <ProtectedRoute>
                <ContractInfo />
            </ProtectedRoute>

    },
    {
        path: '/contracts/create',
        element:         
        <ProtectedRoute>
            <ContractForm />
        </ProtectedRoute>
    },
    {
        path: '/contracts/:id/summary',
        element: <ContractSummary/>
    },
    {
        path: '/contracts/:id/edit',
        element: <ContractForm/>
    },
    {
        path: '/activity-types',
        element: 
        <ProtectedRoute>
            <ActivityTypesPage />
        </ProtectedRoute>
    },

]

const invoiceRoutes = [
    {
        path: '/invoices/:id',
        element: <InvoicePage />
    },
    {
        path: '/invoice_texts/create',
        element: 
        <ProtectedRoute>
            <InvoiceTextForm />
        </ProtectedRoute>
    },
    {
        path: '/invoice_texts/:id/edit',
        element: 
        <ProtectedRoute>
            <InvoiceTextForm />
        </ProtectedRoute>
    },
    {
        path: '/invoice_texts/manage',
        element: 
        <ProtectedRoute>
            <InvoiceTextManagement />
        </ProtectedRoute>
    },
    // {
    //     path: '/wip',
    //     element:
    //         <ProtectedRoute>
    //             <WorkInProgressQueue admin={true} />
    //         </ProtectedRoute>

    // },
    {
        path:'/timesheet',
        element: <Timesheet/>
    },
    {
        path: '/billing',
        element: 
            <ProtectedRoute>
                <Billing/>
            </ProtectedRoute>
        
    },
    {
        path: '/reports',
        element:
        <ProtectedRoute>
            <ReportsPage/>
        </ProtectedRoute>
    }
]

const paymentRoutes = [
    {
        path: '/clients/:clientId/payments',
        element: 
        
        <ProtectedRoute>
            <InvoicePaymentPage />
        </ProtectedRoute>   
    }
]



const routes = [
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            ...clientRoutes,
            ...timeEntryRoutes,
            ...userRoutes,
            ...paymentRoutes,
            ...invoiceRoutes,
            ...contractRoutes,
            {
                path: '/admin',
                element: <AdminPage />
            },
            {
                path: '/error',
                element: <ErrorPage />
            },
            {
                path: '/access-denied',
                element: <AccessDenied />
            }
        ]
    },
    {
        path: '/login',
        element: <LoginPage />
    },

];

export default routes;
