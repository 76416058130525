import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNotify } from '../context/NotificationContext';
import StateDropdown from '../components/StateDropdown';
import '../styles/Client.css'; // Import custom CSS for styling

const ClientForm = () => {
  const { id } = useParams();
  const { error, success } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${APP_URL}/users`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            setUsers(data);
          });
        } else {
          resp.json().then(error);
        }
      })
      .catch((e) => error(e));
  }, []); // eslint-disable-line

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      partner: '',
      clientId: '',
      legalName: '',
      startDate: '',
      status: 'Active',
      address: '',
      city: '',
      state: '',
      zip: '',
      group: '',
      EIN: '',
      mainContact: '',
      mainEmail: '',         
      billingContact: '',    
      billingEmail: ''       
    },
    validationSchema: Yup.object({
      email: Yup.string(),
      phone: Yup.string(),
      partner: Yup.string().required(),
      clientId: Yup.number().required('Client ID is required'),
      legalName: Yup.string().required('Legal Name is required'),
      startDate: Yup.date().required('Start Date is required'),
      status: Yup.string(),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
      group: Yup.string(),
      EIN: Yup.number(),
      mainContact: Yup.string(),
      mainEmail: Yup.string(),
      billingContact: Yup.string(),
      billingEmail: Yup.string()
    }),
    onSubmit: (values) => {
      const payload = {
        email: values.email,
        phone: values.phone,
        partner_id: values.partner,
        client_id: values.clientId,
        legal_name: values.legalName,
        start_date: values.startDate,
        status: values.status,
        address: values.address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        group: values.group,
        ein: values.EIN,
        main_contact: values.mainContact,  
        main_email: values.mainEmail,      
        billing_contact: values.billingContact,  
        billing_email: values.billingEmail  
      };

      const requestOptions = {
        method: id ? 'PATCH' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      };

      fetch(`${APP_URL}/clients${id ? `/${id}` : ''}`, requestOptions)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((data) => {
              success(id ? 'Client successfully updated!' : 'Client created successfully!');
              navigate(`/clients/${id || data['success']}`);
            });
          } else {
            error(resp);
          }
        })
        .catch((e) => error(e));
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (id) {
      fetch(`${APP_URL}/clients/${id}`, {
        credentials: 'include'
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((data) => {
              formik.setValues({
                email: data.email || '',
                phone: data.phone || '',
                partner: data.partner_id || '',
                clientId: data.client_id || '',
                legalName: data.legal_name || '',
                startDate: data.start_date || '',
                status: data.status || '',
                address: data.address || '',
                city: data.city || '',
                state: data.state || '',
                zip: data.zip_code || '',
                group: data.group || '',
                EIN: data.ein || '',
                mainContact: data.main_contact || '',
                mainEmail: data.main_email || '',
                billingContact: data.billing_contact || '',
                billingEmail: data.billing_email || ''
              });
            });
          } else {
            resp.json().then(console.error);
          }
        })
        .catch((e) => error(e));
    }
  }, [id]); // eslint-disable-line

  const handleDeleteClient = () => {
    fetch(`${APP_URL}/clients/${id}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          success('Client Deleted');
          navigate('/');
        } else {
          error(resp);
        }
      })
      .catch((e) => error(e));
  };

  return (
    <div className="container my-3">
      <h2 className='text-2xl font-bold'>{id ? 'Edit Client' : 'Add New Client'}</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className='client-info'>

          <div className="client-info-item mb-3">
            <label htmlFor="clientId" className="form-label">
              Client ID
            </label>
            <input
              type="number"
              className="form-control"
              id="clientId"
              name="clientId"
              value={formik.values.clientId}
              onChange={handleChange}
            />
            {formik.touched.clientId && formik.errors.clientId && (
              <div className="text-danger">{formik.errors.clientId}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="legalName" className="form-label">
              Legal Name
            </label>
            <input
              type="text"
              className="form-control"
              id="legalName"
              name="legalName"
              value={formik.values.legalName}
              onChange={handleChange}
            />
            {formik.touched.legalName && formik.errors.legalName && (
              <div className="text-danger">{formik.errors.legalName}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="mainContact" className="form-label">
              Main Contact
            </label>
            <input
              type="text"
              className="form-control"
              id="mainContact"
              name="mainContact"
              value={formik.values.mainContact}
              onChange={handleChange}
            />
            {formik.touched.mainContact && formik.errors.mainContact && (
              <div className="text-danger">{formik.errors.mainContact}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="mainEmail" className="form-label">
              Main Email
            </label>
            <input
              className="form-control"
              id="mainEmail"
              name="mainEmail"
              value={formik.values.mainEmail}
              onChange={handleChange}
            />
            {formik.touched.mainEmail && formik.errors.mainEmail && (
              <div className="text-danger">{formik.errors.mainEmail}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="billingContact" className="form-label">
              Billing Contact
            </label>
            <input
              type="text"
              className="form-control"
              id="billingContact"
              name="billingContact"
              value={formik.values.billingContact}
              onChange={handleChange}
            />
            {formik.touched.billingContact && formik.errors.billingContact && (
              <div className="text-danger">{formik.errors.billingContact}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="billingEmail" className="form-label">
              Billing Email
            </label>
            <input
              className="form-control"
              id="billingEmail"
              name="billingEmail"
              value={formik.values.billingEmail}
              onChange={handleChange}
            />
            {formik.touched.billingEmail && formik.errors.billingEmail && (
              <div className="text-danger">{formik.errors.billingEmail}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              className="form-control"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={handleChange}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-danger">{formik.errors.phone}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="address" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              name="address"
              value={formik.values.address}
              onChange={handleChange}
            />
            {formik.touched.address && formik.errors.address && (
              <div className="text-danger">{formik.errors.address}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={handleChange}
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-danger">{formik.errors.city}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="state" className="form-label">
              State
            </label>
            <select 
            className="form-select" 
            onChange={(e) => formik.setFieldValue('state',e.target.value)}
            value={formik.values.state}
            >
              <StateDropdown/>
            </select>
            {formik.touched.state && formik.errors.state && (
              <div className="text-danger">{formik.errors.state}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="zip" className="form-label">
              Zip
            </label>
            <input
              type="text"
              className="form-control"
              id="zip"
              name="zip"
              value={formik.values.zip}
              onChange={handleChange}
            />
            {formik.touched.zip && formik.errors.zip && (
              <div className="text-danger">{formik.errors.zip}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="group" className="form-label">
              Group
            </label>
            <input
              type="text"
              className="form-control"
              id="group"
              name="group"
              value={formik.values.group}
              onChange={handleChange}
            />
            {formik.touched.group && formik.errors.group && (
              <div className="text-danger">{formik.errors.group}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="EIN" className="form-label">
              EIN
            </label>
            <input
              type="text"
              className="form-control"
              id="EIN"
              name="EIN"
              value={formik.values.EIN}
              onChange={handleChange}
            />
            {formik.touched.EIN && formik.errors.EIN && (
              <div className="text-danger">{formik.errors.EIN}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="startDate" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              name="startDate"
              value={formik.values.startDate}
              onChange={handleChange}
            />
            {formik.touched.startDate && formik.errors.startDate && (
              <div className="text-danger">{formik.errors.startDate}</div>
            )}
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              id="status"
              name="status"
              value={formik.values.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>

          <div className="client-info-item mb-3">
            <label htmlFor="partner" className="form-label">
              Partner
            </label>
            <select
              className="form-select"
              id="partner"
              name="partner"
              value={formik.values.partner}
              onChange={handleChange}
            >
            <option value={-1}>
               Select Partner
            </option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
            {formik.touched.partner && formik.errors.partner && (
              <div className="text-danger">{formik.errors.partner}</div>
            )}
          </div>
          <div className="client-info-item mb-3"></div>

          <button type="submit" className="btn btn-primary">
            {id ? 'Update Client' : 'Create Client'}
          </button>
          {id && (
            <button
              type="button"
              className="btn btn-danger ms-2"
              onClick={handleDeleteClient}
            >
              Delete Client
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
