import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNotify } from '../context/NotificationContext';

const UserForm = () => {
  const { userId } = useParams();
  const { error, success } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      id: userId || '',
      username: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      status: 'Active',
      startDate: '',
      permissions: 'Staff',
      billingRates: [],
    },
    validationSchema: Yup.object({
      id: Yup.string().required('ID is required'),
      username: Yup.string()
        .min(5, 'Username must be at least 5 characters')
        .max(25, 'Username must be at most 25 characters')
        .required('Username is required'),
      password: userId
        ? Yup.string().min(5, 'Password must be at least 5 characters').max(25, 'Password must be at most 25 characters')
        : Yup.string()
          .min(5, 'Password must be at least 5 characters')
          .max(25, 'Password must be at most 25 characters')
          .required('Password is required'),
      email: Yup.string().email('Invalid email address'),
      firstName: Yup.string(),
      lastName: Yup.string(),
      status: Yup.string(),
      startDate: Yup.date(),
      permissions: Yup.string().oneOf(['Staff', 'Staff Mgr', 'Partner'], 'Invalid permissions').required('Permissions are required'),
      billingRates: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required('Rate name is required'),
            value: Yup.number().min(0, 'Value must be a positive number').required('Value is required'),
          })
        )
        .min(1, 'At least one billing rate is required'),
    }),
    onSubmit: (values) => {
      console.log(values)
      const userData = {
        id: values.id,
        username: values.username,
        password: values.password,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        status: values.status,
        start_date: values.startDate,
        role: values.permissions,
        billing_rates: values.billingRates,
      };

      const method = userId ? 'PATCH' : 'POST';
      const url = userId ? `${APP_URL}/users/${userId}` : `${APP_URL}/users`;

      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify(userData),
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(() => {
              navigate('/users/manage');
              success(method === 'PATCH' ? 'User updated!' : 'User created!');
            });
          } else {
            error(resp);
          }
        })
        .catch((e) => error(e));
    },
  });

  useEffect(() => {
    if (userId) {
      fetch(`${APP_URL}/users/${userId}`, {
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((data) => {
              formik.setValues({
                id: data.id,
                username: data.username,
                email: data.email,
                firstName: data.first_name || '',
                lastName: data.last_name || '',
                status: data.status,
                startDate: data.start_date || '',
                permissions: data.role,
                billingRates: data.rates,
              });
            });
          }
        })
        .catch((e) => {
          error(e);
          navigate('/error');
        });
    } else {
      if (formik.values.billingRates.length === 0) {
        handleAddRate();
      }
    }
  }, [userId]); //eslint-disable-line

  const handleAddRate = () => {
    formik.setValues({
      ...formik.values,
      billingRates: [...formik.values.billingRates, { name: '', value: 0 }],
    });
  };

  const handleRemoveRate = (indexToRemove) => {
    formik.setValues({
      ...formik.values,
      billingRates: formik.values.billingRates.filter((_, index) => index !== indexToRemove),
    });
  };

  return (
    <div className="container mt-4">
      <h2 className="text-2xl font-bold">{userId ? 'Edit User' : 'User Creation'}</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className={`mb-3 ${formik.touched.id && formik.errors.id ? 'has-error' : ''}`}>
          <label htmlFor="id" className="form-label">
            ID
          </label>
          <input
            type="text"
            className={`form-control ${formik.touched.id && formik.errors.id ? 'border-red-500' : ''}`}
            id="id"
            name="id"
            value={formik.values.id}
            onChange={formik.handleChange}
            disabled={userId ? true : false}
          />
          {formik.touched.id && formik.errors.id && <div className="text-red-500">{formik.errors.id}</div>}
        </div>
        <div className={`mb-3 ${formik.touched.username && formik.errors.username ? 'has-error' : ''}`}>
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className={`form-control ${formik.touched.username && formik.errors.username ? 'border-red-500' : ''}`}
            id="username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          {formik.touched.username && formik.errors.username && (
            <div className="text-red-500">{formik.errors.username}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.password && formik.errors.password ? 'has-error' : ''}`}>
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className={`form-control ${formik.touched.password && formik.errors.password ? 'border-red-500' : ''}`}
            id="password"
            name="password"
            placeholder={userId ? 'Leave blank to keep password' : ''}
            value={formik.values.password || ''}
            onChange={formik.handleChange}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="text-red-500">{formik.errors.password}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.email && formik.errors.email ? 'has-error' : ''}`}>
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className={`form-control ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-500">{formik.errors.email}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.firstName && formik.errors.firstName ? 'has-error' : ''}`}>
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className={`form-control ${formik.touched.firstName && formik.errors.firstName ? 'border-red-500' : ''}`}
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className="text-red-500">{formik.errors.firstName}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.lastName && formik.errors.lastName ? 'has-error' : ''}`}>
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className={`form-control ${formik.touched.lastName && formik.errors.lastName ? 'border-red-500' : ''}`}
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="text-red-500">{formik.errors.lastName}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.status && formik.errors.status ? 'has-error' : ''}`}>
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <select
            className={`form-control ${formik.touched.status && formik.errors.status ? 'border-red-500' : ''}`}
            id="status"
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
          >
            <option value='Active'>Active</option>
            <option value='Inactive'>Inactive</option>
          </select>
          {formik.touched.status && formik.errors.status && (
            <div className="text-red-500">{formik.errors.status}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.startDate && formik.errors.startDate ? 'has-error' : ''}`}>
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <input
            type="date"
            className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'border-red-500' : ''}`}
            id="startDate"
            name="startDate"
            value={formik.values.startDate}
            onChange={formik.handleChange}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <div className="text-red-500">{formik.errors.startDate}</div>
          )}
        </div>
        <div className={`mb-3 ${formik.touched.permissions && formik.errors.permissions ? 'has-error' : ''}`}>
          <label htmlFor="permissions" className="form-label">
            Permissions
          </label>
          <select
            className={`form-control ${formik.touched.permissions && formik.errors.permissions ? 'border-red-500' : ''}`}
            id="permissions"
            name="permissions"
            value={formik.values.permissions}
            onChange={formik.handleChange}
          >
            <option value="Staff">Staff</option>
            <option value="Staff Mgr">Staff Mgr</option>
            <option value="Partner">Partner</option>
          </select>
          {formik.touched.permissions && formik.errors.permissions && (
            <div className="text-red-500">{formik.errors.permissions}</div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Billing Rates</label>
          {formik.values.billingRates.map((rate, index) => (
            <div key={index} className={`mb-1 flex ${formik.touched.billingRates && formik.errors.billingRates ? 'has-error' : ''}`}>
              <input
                type="text"
                className={`form-control mr-2 ${formik.touched.billingRates && formik.errors.billingRates ? 'border-red-500' : ''}`}
                placeholder="Rate Name"
                name={`billingRates[${index}].name`}
                value={rate.name}
                onChange={formik.handleChange}
              />
              <input
                type="number"
                className={`form-control mr-2 ${formik.touched.billingRates && formik.errors.billingRates ? 'border-red-500' : ''}`}
                placeholder="Value"
                name={`billingRates[${index}].value`}
                value={rate.value}
                onChange={formik.handleChange}
              />
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRemoveRate(index)}
              >
                Remove
              </button>
            </div>

          ))}
          <div className='mb-2'>
          {formik.touched.billingRates && formik.errors.billingRates && (
                <div className="text-red-500">{formik.errors.billingRates[0].name}</div>
            )}
          </div>
          
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleAddRate}
          >
            Add Rate
          </button>
          
        </div>
        
        <button type="submit" className="btn btn-primary">
          {userId ? 'Update' : 'Create'}
        </button>
      </form>
    </div>
    
  );
};

export default UserForm;
