import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useUtils } from '../context/UtilContext';

const TimeEntryForm = () => {
    const { id } = useParams();
    const { error, success, choice } = useNotify();
    const [clients, setClients] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const [isCompanyAccount, setIsCompanyAccount] = useState(false)
    const [rates, setRates] = useState([]); // State for rates
    const { getCookie, APP_URL, user  } = useAuth();
    const {formatDate, formatCurrency} = useUtils()

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            hours: '',
            startDate: '',
            client: null,
            contract: null,
            activityType: null,
            rate: null // Add rate to initial values
        },
        validationSchema: Yup.object({
            hours: Yup.number().min(0, 'Hours Spent must be greater than or equal to 0').required('Hours Spent is required'),
            startDate: Yup.date()
                .required('Start Date is required')
                .test('is-after-contract-start', 'Start Date cannot be before the contract start date', function(value) {
                    const { contract } = this.parent;
                    if (!contract || !value) return true;
                    const selectedContract = contracts.find(c => c.id === contract.value);
                    if (!selectedContract) return true;
                    return new Date(value) >= new Date(selectedContract.start_date);
                }),
            client: Yup.object().required('Client is required'),
            contract: Yup.object().required('Contract is required'),
            activityType: Yup.object().required('Activity Type is required'),
            rate: Yup.object().required('Rate is required') // Add validation for rate
        }),
        onSubmit: values => {

            const payload = {
                client_id: values.client.value,
                hours: values.hours,
                start_date: values.startDate,
                contract_id: values.contract.value,
                activity_type_id: values.activityType.value,
                rate_id: values.rate.value // Include rate in payload
            };

            fetch(`${APP_URL}/time_entries${id ? '/' + id : ''}`, {
                method: id ? 'PATCH' : 'POST',
                headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': getCookie('csrf_access_token') },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            success(id ? 'Time entry updated successfully!' : 'Time entry created successfully!');
                            navigate(`/profile`);
                        });
                    } else {
                        error(resp);
                    }
                })
                .catch(e => {
                    error(e);
                });
        },
    });

    useEffect(() => {
        if (id && window.location.pathname.endsWith('/edit')) {
            fetch(`${APP_URL}/time_entries/${id}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            formik.setValues({
                                startDate: formatDate(data.start_date),
                                hours: data.hours,
                                client: { value: data.client.id, label: data.client.company_name },
                                contract: { value: data.contract.id, label: data.contract.id },
                                activityType: { value: data.activity_type.id, label: data.activity_type.name, charge_type:data.activity_type.charge_type },
                                rate: { value: data.rate.id, label: data.rate.name } // Set initial value for rate
                            });
                        });
                    } else {
                        error(resp);
                        navigate('/error');
                    }
                })
                .catch(e => error(e));
        }

        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => setClients(data));
                } else {
                    navigate('/error');
                }
            });

        if (user) {
            fetch(`${APP_URL}/users/${user?.id}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => setRates(data.rates)); // Set rates from response
                    } else {
                        navigate('/error');
                    }
                });
        }

    }, [id, user]); //eslint-disable-line

    useEffect(() => {
        if (formik.values.client?.value !== undefined) {
            fetch(`${APP_URL}/clients/${formik.values.client.value}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            if(data.id === 0){
                                setIsCompanyAccount(true)
                                formik.setFieldValue('contract',{value:0})
                            }else{
                                setIsCompanyAccount(false)
                            }
                            setContracts(data.contracts);
                            setActivityTypes(data.activity_types);
                        });
                    } else {
                        error(resp);
                    }
                }).catch(e => error(e));
        } else {
            // Reset contracts and activity types if client is deselected
            
            setContracts([]);
            setActivityTypes([]);
        }
    }, [formik.values.client, APP_URL, error]); //eslint-disable-line



    const handleDeleteTimeEntry = () => {
        fetch(`${APP_URL}/time_entries/${id}`, {
            method: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    success('Time entry deleted!');
                    navigate(`/clients/${formik.values.client_id}`);
                } else {
                    error(resp);
                }
            })
            .catch(e => error(e));
    };

    const handleSubmit = (e) =>{

        e.preventDefault()

        const startDate = new Date(formik.values.startDate);

        const currentFriday = new Date();
        currentFriday.setDate(currentFriday.getDate() + (5 - currentFriday.getDay()));

        const previousSaturday = new Date(currentFriday);
        previousSaturday.setDate(currentFriday.getDate() - 6);

        if (startDate < previousSaturday || startDate > currentFriday) {
            choice('You are entering a outside of the current sheet week. Did you mean to do so?',()=>formik.handleSubmit(e))
        }else{
            formik.handleSubmit(e)
        }
    }


    const clientOptions = clients.map(client => {
        return { value: client.id, label: `${client.legal_name} - ${client.client_id}` };
    });

    const contractOptions = contracts.filter(contract => !contract.end_date).map(contract => {
        return { value: contract.id, label: `${contract.id}` };
    });

    const activityTypeOptions = activityTypes.map(type => {
        return { value: type.id, label: type.name, chargeType: type.charge_type };
    });

    const rateOptions = rates.map(rate => {
        return { value: rate.id, label: `${rate.name} - ${rate.value}` };
    });

    const desiredCompanyIndex = clientOptions.findIndex(option => option.id === 0);

    if (desiredCompanyIndex !== -1) {
        const desiredCompany = clientOptions.splice(desiredCompanyIndex, 1)[0];
        clientOptions.unshift(desiredCompany);
    }

    console.log(activityTypeOptions)
    console.log(activityTypeOptions.find(option => option.value === formik.values.activityType?.value))
    console.log(formik.values.activityType)

    return (
        <div className="container mt-5">
            <h2 className='text-2xl font-bold'>{id ? 'Edit Time Entry' : 'Create Time Entry'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="client" className="form-label">
                        Client
                    </label>
                    <Select
                        options={clientOptions}
                        value={clientOptions.find(option => option.value === formik.values.client?.value)}
                        onChange={(selectedOption) => {formik.setFieldValue('client', selectedOption);formik.setFieldValue('activityType',null)}}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.client && formik.errors.client ? 'border-red-500' : ''}`}
                    />
                    {formik.touched.client && formik.errors.client && <div className="text-red-500">{formik.errors.client}</div>}
                </div>
                {!isCompanyAccount && <div className="mb-3">
                    <label htmlFor="contract" className="form-label">
                        Contract
                    </label>
                    <Select
                        options={contractOptions}
                        value={contractOptions.find(option => option.value === formik.values.contract?.value) || null}
                        onChange={selectedOption => {
                            formik.setFieldValue('contract', selectedOption);
                        }}
                        onBlur={formik.handleBlur}
                        isDisabled={!formik.values.client}
                        className={`form-control ${formik.touched.contract && formik.errors.contract ? 'border-red-500' : ''}`}
                    />
                    {formik.touched.contract && formik.errors.contract && <div className="text-red-500">{formik.errors.contract}</div>}
                </div>}
                <div className="mb-3">
                    <label htmlFor="activityType" className="form-label">
                        Activity Type
                    </label>
                    <Select
                        options={activityTypeOptions}
                        value={activityTypeOptions.find(option => option.value === formik.values.activityType?.value) || null}
                        isDisabled={!formik.values.client}
                        onChange={selectedOption => formik.setFieldValue('activityType', selectedOption)}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.activityType && formik.errors.activityType ? 'border-red-500' : ''}`}
                    />
                    {formik.touched.activityType && formik.errors.activityType && <div className="text-red-500">{formik.errors.activityType}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="rate" className="form-label">
                        Rate
                    </label>
                    <Select
                        options={rateOptions}
                        value={rateOptions.find(option => option.value === formik.values.rate?.value)}
                        onChange={(selectedOption) => formik.setFieldValue('rate', selectedOption)}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.rate && formik.errors.rate ? 'border-red-500' : ''}`}
                    />
                    {formik.touched.rate && formik.errors.rate && <div className="text-red-500">{formik.errors.rate}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="hours" className="form-label">
                        Hours Spent
                    </label>
                    <input type="number" className={`form-control ${formik.touched.hours && formik.errors.hours ? 'border-red-500' : ''}`} id="hours" name="hours" value={formik.values.hours || ''} min="0" step="0.25" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched.hours && formik.errors.hours && <div className="text-red-500">{formik.errors.hours}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                        Start Date
                    </label>
                    <input type="date" className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'border-red-500' : ''}`} id="startDate" name="startDate" value={formik.values.startDate || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched.startDate && formik.errors.startDate && <div className="text-red-500">{formik.errors.startDate}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="hours" className="form-label">
                        Total
                    </label>
                    <input type="decimal" disabled={true} className={`form-control`} id="total" name="total" value={(formik.values.activityType?.chargeType && formik.values.rate && formik.values.hours )? formatCurrency(formik.values.hours * rates.find(rate => rate.id === formik.values.rate?.value)?.value) : 'N/A'} min="0" step="1" onBlur={formik.handleBlur} />
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2">
                    {id ? 'Update Time Entry' : 'Create Time Entry'}
                </button>
                {id && (
                    <button type="button" className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={handleDeleteTimeEntry}>
                        Delete Time Entry
                    </button>
                )}
            </form>
        </div>
    );
};

export default TimeEntryForm;
