import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';

const Timesheet = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [timesheetData, setTimesheetData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const {formatDate} = useUtils()

  const { APP_URL, getCookie } = useAuth();
  const { error } = useNotify();

  const fetchData = () => {
    if (!startDate || !endDate) {
      alert('Please select start and end dates.');
      return;
    }
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    setFetchingData(true);
    fetch(`${APP_URL}/timesheet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token')
      },
      credentials: 'include',
      body: JSON.stringify({ start_date: formattedStartDate, end_date: formattedEndDate }),
    }).then(resp => {
      if (resp.ok) {
        resp.json().then(setTimesheetData);
      } else {
        error(resp);
      }
    }).catch(e => error(e)).finally(() => setFetchingData(false));
  };

  const fetchUserTimeEntries = (userId) => {
    if (!startDate || !endDate) {
        alert('Please select start and end dates.');
        return;
      }
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    fetch(`${APP_URL}/timesheet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token')
      },
      credentials: 'include',
      body: JSON.stringify({ start_date: formattedStartDate, end_date: formattedEndDate, user_id: userId }),
    }).then(resp => {
      if (resp.ok) {
        resp.json().then(data => {
          setUserData(data);
        });
      } else {
        error(resp);
      }
    }).catch(e => error(e));
  };

  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
    fetchUserTimeEntries(userId);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <label className="block mb-2" htmlFor="start_date">Start Date:</label>
        <DatePicker id='start_date' selected={startDate} onChange={(date) => setStartDate(date)} className="p-2 border border-gray-300 rounded-md" />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="end_date">End Date:</label>
        <DatePicker id='end_date' selected={endDate} onChange={(date) => setEndDate(date)} className="p-2 border border-gray-300 rounded-md" />
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={fetchData}
        disabled={fetchingData}
      >
        {fetchingData ? 'Fetching...' : 'Fetch Data'}
      </button>
      <div className="mt-4">
        {timesheetData.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-500">
              <thead>
                <tr>
                  <th className="border border-gray-500 px-4 py-2">User ID</th>
                  <th className="border border-gray-500 px-4 py-2">User Name</th>
                  <th className="border border-gray-500 px-4 py-2">Total Hours Worked</th>
                </tr>
              </thead>
              <tbody>
                {timesheetData.map((user, index) => (
                  <tr key={index} onClick={() => handleUserClick(user.user_id)} style={{ cursor: 'pointer' }}>
                    <td className="border border-gray-500 px-4 py-2">{user.user_id}</td>
                    <td className="border border-gray-500 px-4 py-2">{user.username}</td>
                    <td className="border border-gray-500 px-4 py-2">{user.total_hours}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="border border-gray-500 px-4 py-2"><strong>Total</strong></td>
                  <td className="border border-gray-500 px-4 py-2"></td>
                  <td className="border border-gray-500 px-4 py-2"><strong>{timesheetData.reduce((acc, user) => acc + user.total_hours, 0)}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <p>Please select a date range and press "Fetch Data".</p>
        )}
      </div>
      {selectedUserId && (
        <div className="mt-4">
          <table className="w-full border-collapse border border-gray-500">
            <thead>
              <tr>
                <th className="border border-gray-500 px-4 py-2">User ID</th>
                <th className="border border-gray-500 px-4 py-2">Username</th>
                <th className="border border-gray-500 px-4 py-2">Start Date</th>
                <th className="border border-gray-500 px-4 py-2">Contract Id</th>
                <th className="border border-gray-500 px-4 py-2">Contract Type</th>
                <th className="border border-gray-500 px-4 py-2">Hours</th>
                {/* <th className="border border-gray-500 px-4 py-2">User Name</th>
                <th className="border border-gray-500 px-4 py-2">User Name</th>
                <th className="border border-gray-500 px-4 py-2">Hours Worked</th> */}
              </tr>
            </thead>
            <tbody>
              {userData.time_entries?.map((timeEntry, index) => (
                <tr key={index}>
                <td className="border border-gray-500 px-4 py-2">{userData.user_id}</td>
                <td className="border border-gray-500 px-4 py-2">{userData.username}</td>
                <td className="border border-gray-500 px-4 py-2">{formatDate(timeEntry.date)}</td>
                <td className="border border-gray-500 px-4 py-2">{timeEntry.contract_id}</td>
                <td className="border border-gray-500 px-4 py-2">{timeEntry.activity_type?.name}</td>
                  <td className="border border-gray-500 px-4 py-2">{timeEntry.hours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Timesheet;
