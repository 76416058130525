import { useAuth } from "../context/AuthContext"
import AccessDenied from "../pages/AccessDenied"

const ProtectedRoute = ({ children }) => {
    const {user} = useAuth()

    if(user.role === 'Partner'){
        return children
    }

    return <AccessDenied/>
}

export default ProtectedRoute;