import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';

const InvoicePaymentPage = () => {
    const { clientId } = useParams();
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [searchParams] = useSearchParams()
    const invoice_id = searchParams.get('invoice_id');
    const contract_id = searchParams.get('contract_id');
    const { error, success } = useNotify()
    const { getCookie, APP_URL } = useAuth()

    useEffect(() => {
        fetch(`${APP_URL}/clients/${clientId}`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        setContracts(data.contracts);
                        if(contract_id){
                            const contract = data.contracts.find(contract => contract.id == contract_id)
                            if(contract){
                                setSelectedContract({ value: contract.id, label: `${contract.id}` })
                            }
                            if(invoice_id){
                                const invoice = data.invoices.find(invoice => invoice.id == invoice_id)
                                if(invoice){
                                    setSelectedInvoice({ value: invoice.id, label: `${invoice.id}` })
                                }
                            }
                        }
                    });
                } else {
                    console.error('Error fetching invoices');
                }
            })
            .catch(error => console.error('Error:', error));

    }, []); //eslint-disable-line

    useEffect(() => {
        if (selectedContract) {
            fetchInvoices()
        }

    }, [selectedContract]) //eslint-disable-line

    const fetchInvoices = () => {
        fetch(`${APP_URL}/clients/${clientId}`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        setInvoices(data.invoices.filter(invoice => invoice.contract_id === selectedContract.value));
                        const updatedInvoice = data.invoices.find(invoice => invoice.id === selectedInvoice?.value);
                        if (updatedInvoice) {
                            setSelectedInvoice({
                                value: updatedInvoice.id,
                                label: `Invoice ID: ${updatedInvoice.id} - Total: $${updatedInvoice.total} - Remaining: $${updatedInvoice.remaining_total}`,
                            });
                        }
                    });
                } else {
                    error(resp)
                }
            })
            .catch(error => console.error('Error:', error));
    }

    const handleContractChange = (selectedOption) => {
        setSelectedContract(selectedOption);
        setSelectedInvoice(null); // Reset selected invoice when contract changes
    };

    const handlePayment = () => {
        // Perform payment logic here, using selectedInvoice and paymentAmount
        console.log('Payment submitted:', selectedInvoice, paymentAmount);


        fetch(`${APP_URL}/payments`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
            body: JSON.stringify({
                invoice_id: selectedInvoice.value,
                amount: parseFloat(paymentAmount)
            }),
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    success('Payment made!')
                    fetchInvoices()
                    setSelectedContract(null)
                    setSelectedInvoice(null)
                    setPaymentAmount(0)

                } else {
                    error(resp)
                }
            })
        // You can send a request to the server to process the payment
    };

    console.log(selectedContract)

    const contractOptions = contracts.map(contract => ({
        value: contract.id,
        label: `${contract.id}`,
    }));

    const invoiceOptions = invoices.map(invoice => ({
        value: invoice.id,
        label: `Invoice ID: ${invoice.id} - Total: $${invoice.total} - Remaining: $${invoice.remaining_total}`,
    }));

    return (
        <div className="container mt-5">
            <h2 className="text-2xl font-bold">Make a Payment</h2>
            <div className="mb-3">
                <label htmlFor="contract" className="form-label">
                    Select Contract
                </label>
                <Select
                    options={contractOptions}
                    value={selectedContract}
                    onChange={handleContractChange}
                    className="form-control"
                />
            </div>
            {selectedContract && (
                <div className="mb-3">
                    <label htmlFor="invoice" className="form-label">
                        Select Invoice
                    </label>
                    <Select
                        options={invoiceOptions}
                        value={selectedInvoice}
                        onChange={setSelectedInvoice}
                        className="form-control"
                    />
                </div>
            )}
            <div className="mb-3">
                <label htmlFor="amount" className="form-label">
                    Payment Amount
                </label>
                <input
                    type="number"
                    className="form-control"
                    id="amount"
                    value={paymentAmount || ''}
                    onChange={e => setPaymentAmount(parseFloat(e.target.value))}
                />
            </div>
            <button
                className="btn btn-primary"
                onClick={handlePayment}
                disabled={!selectedInvoice || paymentAmount <= 0}
            >
                Make Payment
            </button>
        </div>
    );
};

export default InvoicePaymentPage;
