import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useParams, useNavigate } from 'react-router-dom';

const InvoiceTextForm = () => {
  const { APP_URL, getCookie } = useAuth();
  const { success, error } = useNotify();
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [initialValues, setInitialValues] = useState({
    name: '',
    text: '',
  });

  useEffect(() => {
    if (id) {
      fetch(`${APP_URL}/invoice_texts/${id}`, {
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            error(resp)
          }
        })
        .then((data) => {
          setInitialValues({
            name: data.name,
            text: data.text,
          });
        })
        .catch((e) => error(e));
    }
  }, [id, APP_URL, error]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Name must be 50 characters or less')
        .required('Name is required'),
      text: Yup.string().required('Text is required'),
    }),
    onSubmit: (values) => {
      const url = id ? `${APP_URL}/invoice_texts/${id}` : `${APP_URL}/invoice_texts`;
      const method = id ? 'PATCH' : 'POST';

      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify(values),
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            success('Invoice text submitted successfully');
            navigate('/invoice_texts/manage');
          } else {
            error(resp)
          }
        })
        .catch((e) => error(e));
    },
  });

  return (
    <div className="container mt-4">
      <h2 className="text-2xl font-bold">{id ? 'Edit Invoice Text' : 'New Invoice Text'}</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className="form-label">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            className={`form-control ${formik.touched.name && formik.errors.name ? 'border-red-500' : ''}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="text" className="form-label">Text</label>
          <textarea
            id="text"
            name="text"
            className={`form-control ${formik.touched.text && formik.errors.text ? 'border-red-500' : ''}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.text}
          />
          {formik.touched.text && formik.errors.text ? (
            <div className="text-red-500">{formik.errors.text}</div>
          ) : null}
        </div>
        <button type="submit" className="btn btn-primary mt-2">Submit</button>
      </form>
    </div>
  );
};

export default InvoiceTextForm;
