import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';

const InvoiceTextManagement = () => {
  const { APP_URL, getCookie } = useAuth();
  const { success, error, choice } = useNotify();
  const [invoiceTexts, setInvoiceTexts] = useState([]);

  useEffect(() => {
    fetch(`${APP_URL}/invoice_texts`, {
      credentials: 'include',
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          error(resp)
        }
      })
      .then((data) => {
        setInvoiceTexts(data);
      })
      .catch((e) => error(e));
  }, [APP_URL, error]);


  const handleDelete = (id) =>{
    choice(`Are you sure you want to delete this invoice text?`,()=>handleConfirmDelete(id))
  }

  const handleConfirmDelete = (id) => {
      fetch(`${APP_URL}/invoice_texts/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            setInvoiceTexts(invoiceTexts.filter((text) => text.id !== id));
            success('Invoice text deleted successfully');
          } else {
            error(resp)
          }
        })
        .catch((e) => error(e));
  };

  return (
    <div className="container mt-4">
      <h2 className="text-2xl font-bold">Manage Invoice Texts</h2>
      <Link to="/invoice_texts/create" className="btn btn-primary my-2">Create Invoice Text</Link>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Text</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoiceTexts.map((text) => (
            <tr key={text.id}>
              <td>{text.name}</td>
              <td>{text.text}</td>
              <td>
                <Link to={`/invoice_texts/${text.id}/edit`} className="btn btn-warning mr-2">Edit</Link>
                <button onClick={() => handleDelete(text.id)} className="btn btn-danger">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTextManagement;
