import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/InvoicePage.css'
import { useUtils } from '../context/UtilContext';

const InvoicePage = () => {
  const { id } = useParams();
  const { APP_URL } = useAuth();
  const { formatCurrency } = useUtils()
  const { error } = useNotify();
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${APP_URL}/invoices/${id}`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => setInvoice(data));
          setLoading(false)
        } else {
          if (resp.status === 401 && resp.json) {
            resp.json().then(data => {
              if (!data['msg']) {
                navigate('/access-denied')
              }
            })

          } else {
            navigate('/error')
            error(resp)
          }

        }
      })
      .catch((e) => error(e));
  }, [id]); //eslint-disable-line

  // const handleDeleteClick = () => {
  //   choice(`Are you sure you want to delete this invoice?`, handleDeleteInvoice);
  // };

  // const handleDeleteInvoice = () => {
  //   success('Deleting invoice...');
  //   fetch(`${APP_URL}/invoices/${id}`, {
  //     method: 'DELETE',
  //     headers: {
  //       'X-CSRF-TOKEN': getCookie('csrf_access_token'),
  //     },
  //     credentials: 'include'
  //   })
  //     .then((resp) => {
  //       if (resp.ok) {
  //         success('Invoice deleted!');
  //         navigate(`/requests/${invoice.request_id}`);
  //       } else {
  //         error(resp);
  //       }
  //     })
  //     .catch((e) => error(e));
  // };

  const formatLongDate = (dateStr) => {
    if (dateStr) {
      const date = new Date(dateStr);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }
    return '';
  };

  console.log(invoice.time_entries)

  return !loading ? (

    <div className="container mx-auto my-10 p-8 bg-white shadow-lg max-w-4xl">
      <Link to={`/clients/${invoice.client_id}`}>
        <button className="btn btn-primary element-to-hide">Back to client</button>
      </Link>
      {/* <button className="btn btn-danger element-to-hide" onClick={handleDeleteClick}>
        Delete invoice
      </button> */}
      <div className="flex justify-between border-b-2 border-blue-800 pb-6">
        <div>
          <h1 className="text-3xl font-bold uppercase">
            Invoice #{invoice.id}
          </h1>
          <p className="text-base">
            {formatLongDate(invoice.created_at)}
          </p>
        </div>
        <div className="text-right">
          <h2 className="text-3xl font-bold uppercase text-blue-800">
            Business Accounting
          </h2>
        </div>
      </div>
      <div className="flex justify-between items-center mt-8">
        <div>
          <h3 className="font-bold text-lg">
            Payable to
          </h3>
          <p className="text-base">
            Rachel Locke
          </p>
          <p className="text-base">
            123 test street
          </p>
          <p className="text-base">
            Phoenix, Arizona
          </p>
        </div>
        <div className="text-right">
          <h3 className="font-bold text-lg">
            Client
          </h3>
          <p className="text-base">
            {invoice.client?.legal_name}
          </p>
          {/* <p className="text-base">
            
          </p> */}
          <p className="text-base">
            {invoice.client?.email}
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h3 className="font-bold text-lg border-b-2 border-green-500">
          Services
        </h3>
        <p className="text-base text-center ">
          <b>{invoice.text}</b>
        </p>
        {/* {invoice?.time_entries?.map(timeEntry => {
          return (
            <React.Fragment key={timeEntry.id}>
              <div className="flex justify-between mt-4">
                <p className="text-base">
                  {timeEntry.activity_type?.name}
                </p>
                <p className="text-base">
                  {timeEntry.client?.company_name === 'Fester & Chapman' ? "N/A" : formatCurrency(timeEntry.total)}
                </p>
              </div>
              <small>{timeEntry.hours} hours</small>
            </React.Fragment>
          )
        })} */}
        <div className="flex justify-between mt-6 border-t-2 border-gray-300 pt-4">
          <h3 className="font-bold text-lg">
            Total
          </h3>
          <p className="font-bold text-lg">
            {formatCurrency(invoice.total)}
          </p>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-sm">
          Terms &amp; Conditions
        </p>
        <p className="text-xs mt-2">
          A Terms and Conditions agreement is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to make the payment.
        </p>
      </div>
      <div className="mt-8 bg-blue-800 text-white text-center py-4 element-to-show">
        <h3 className="text-2xl font-bold">
          Thank you for being our valued customer!
        </h3>
      </div>

    </div>
  ) : <h1 className="text-center text-4xl text-gray-700 font-bold mt-10">Loading...</h1>
};

export default InvoicePage;
