import { createContext, useContext, useState } from 'react';
import { useNotify } from './NotificationContext';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const { error } = useNotify();

    // Function to get a specific cookie value by name
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return '';
    };

    // Env variable for distinction between production and development
    const DEV = process.env.REACT_APP_DEV;
    const APP_URL = DEV ? 'http://127.0.0.1:5050' : 'https://api.clockwisecpa.app';

    // The reason im returning the fetch and returning true and false is because im passing these functions
    // out of this component since its being shared through useContext() and i want full control of the fetch when im making the login and logout requests
    // this is also why im returning the error messages this way

    // Function to refresh the user session
    const refreshUser = () => {
        return fetch(`${APP_URL}/user`, {
            credentials: 'include'
        }).then(resp => {
            if (resp.ok) {
                resp.json().then(data => setUser(data));
                return true;
            } else {
                if (resp.status === 401) {
                    fetch(`${APP_URL}/refresh`, {
                        headers: {
                            'X-CSRF-TOKEN': getCookie('csrf_refresh_token')
                        },
                        credentials: 'include'
                    }).then(res => {
                        if (res.ok) {
                            res.json().then(data => setUser(data));
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .catch(e => error(e));
                } else {
                    error(resp);
                    return false;
                }
            }
        }).catch(e => error(e));
    };

    // Function to handle user login
    const login = (username, password) => {
        return fetch(`${APP_URL}/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password }),
            credentials: 'include'
        }).then(async (resp) => {
            if (resp.ok) {
                resp.json().then(data => setUser(data));
                return 'Successfully logged in!';
            } else {
                return await resp.json().then(data => { 
                    return { 'error': data['error'] }; 
                });
            }
        }).catch(e => { return { 'error': e.message } });
    };

    // Function to handle user sign-up
    const signUp = (username, password) => {
        return fetch(`${APP_URL}/signup`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ "username": username, "password": password })
        })
        .then((resp) => resp.json().then(async (data) => {
            if (resp.ok) {
                setUser(data);
                return true;
            } else {
                return await resp.json().then(data => data);
            }
        }))
        .catch(e => e);
    };

    // Function to handle user logout
    const logout = () => {
        return fetch(`${APP_URL}/logout`, {
            method: 'DELETE',
            headers: {
                "X-CSRF-TOKEN": getCookie('csrf_access_token')
            },
            credentials: 'include'
        }).then(async (resp) => {
            if (resp.status === 204) {
                setUser(null);
                return true;
            } else {
                return await resp.json().then(data => {
                    return { 'error': data['error'] }; 
                });
            }
        }).catch(e => { return { 'error': e.message } });
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, signUp, refreshUser, getCookie, APP_URL, DEV }}>
            {children}
        </AuthContext.Provider>
    );
};
