import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import { useUtils } from '../context/UtilContext';

const ContractSummary = () => {
    const [events, setEvents] = useState([]);
    const { id } = useParams();
    const { error } = useNotify();
    const { APP_URL } = useAuth();
    const { formatCurrency } = useUtils()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${APP_URL}/contracts/${id}/summary`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        // Sort events by created_at
                        data.sort((a, b) => {
                            const aTimestamp = a.created_at || 0;
                            const bTimestamp = b.created_at || 0;
                            return aTimestamp - bTimestamp;
                        });

                        setEvents(data);
                        setLoading(false); // Set loading to false after data is fetched
                    });
                } else {
                    navigate('/error');
                    error(resp)
                }
            })
            .catch(e => {
                error(e);
                setLoading(false); // Set loading to false if there's an error
            });
    }, [id, APP_URL, navigate, error]);

    let debit = 0;
    let credit = 0;

    if (loading) {
        return <h1 className="text-center text-4xl text-gray-700 font-bold mt-10">Loading...</h1>;
    }

    return (
        <div className="container mt-5">
            <h2 className="text-2xl font-bold">Contract Summary</h2>
            <button className="btn btn-primary mb-3" onClick={() => navigate(`/contracts/${id}/edit`)}>
                Edit Contract
            </button>
            <div className="mt-5">
                {events.length === 0 ? (
                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                        <p className="font-bold">No invoices or payments found for this contract. <button className='underline' onClick={() => navigate(-1)}>Go back?</button></p>
                    </div>
                ) : (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event Type</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event ID</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Debit</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credit</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {events.map(event => {
                                if (event.contract_id) { // Invoice
                                    debit += event.total || 0;
                                    return (
                                        <tr key={event.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">Invoice</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{event.id}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(debit.toFixed(2))}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(credit.toFixed(2))}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency((debit - credit).toFixed(2))}</td>
                                        </tr>
                                    );
                                } else if (event.payment_amount) { // Payment
                                    credit += event.payment_amount;
                                    return (
                                        <tr key={event.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">Payment</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{event.id}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(debit.toFixed(2))}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(credit.toFixed(2))}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency((debit - credit).toFixed(2))}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                            <tr>
                                <td colSpan="2"></td>
                                <td className="px-6 py-4 whitespace-nowrap font-semibold">Total Debit: {formatCurrency(debit.toFixed(2))}</td>
                                <td className="px-6 py-4 whitespace-nowrap font-semibold">Total Credit: {formatCurrency(credit.toFixed(2))}</td>
                                <td className="px-6 py-4 whitespace-nowrap font-semibold">End Balance: {formatCurrency((debit - credit).toFixed(2))}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ContractSummary;
