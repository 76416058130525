import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto mt-5">
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="bg-red-500 p-6 rounded-md shadow-md">
            <h4 className="text-white text-2xl font-bold mb-4">You do not have access to view this page</h4>
            <p className="text-white mb-4">Please log in as an administrator to access the content, or have an admin grant you permission.</p>
            <hr className="border-white my-4" />
            <p className="text-white">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue"
                onClick={() => navigate('/login?popup=true')}
              >
                Log In
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
