import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';

const UserManagement = () => {
  const { getCookie, APP_URL } = useAuth();
  const { error, success, choice } = useNotify();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState('table'); // New state for toggling view mode
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${APP_URL}/users`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            console.log(data)
            setUsers(data)
          });
        } else {
          if (resp.status !== 401) {
            navigate('/error');
          }
        }
      })
      .catch((e) => error(e));
  }, []); //eslint-disable-line

  const handleSearch = () => {
    const filteredUsers = users.filter((_user) =>
      _user.username.includes(searchQuery) || _user.id.toString().includes(searchQuery)
    );
    setUsers(filteredUsers);
  };

  const handleEdit = (_user) => {
    navigate(`/users/${_user.id}/edit`);
  };

  const handleDelete = (_user) => {
    choice(`Are you sure you want to delete user:${_user.username}?`, () => handleConfirmDelete(_user));
  };

  // console.log(users)

  const handleConfirmDelete = (_user) => {
    fetch(`${APP_URL}/users/${_user.id}`, {
      method: "DELETE",
      credentials: 'include',
      headers: {
        "X-CSRF-TOKEN": getCookie('csrf_access_token'),
      }
    })
      .then(resp => {
        if (resp.ok) {
          setUsers(prev => prev.filter(usr => usr.id !== _user.id));
          success("User deleted!");
        } else {
          resp.json().then(error);
        }
      }).catch(e => error(e));
  };

  return (
    <div className="container mt-4">
      <h2 className='text-2xl font-bold'>User Management</h2>
      <div className="mb-3">
        <div className='input-group mb-2'>
          <input
            type="text"
            className="form-control"
            placeholder="Search by username or ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
        </div>
        <button className="btn btn-primary" onClick={() => navigate('/users/create')}>
          Create User
        </button>
        <button className="btn btn-secondary ml-2" onClick={() => setViewMode(viewMode === 'table' ? 'card' : 'table')}>
          {viewMode === 'table' ? 'Switch to Card View' : 'Switch to Table View'}
        </button>
      </div>
      {viewMode === 'table' ? (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    className="btn btn-warning btn-sm mx-1"
                    onClick={() => handleEdit(user)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger btn-sm mx-1"
                    onClick={() => handleDelete(user)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.map((user) => (
            <div key={user.id} className="card border p-4">
              <h3 className="text-lg font-bold mb-2">Username: {user.username}</h3>
              <p><strong>ID:</strong> {user.id}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Status:</strong> {user.status}</p>
              <p><strong>Start Date:</strong> {user.start_date}</p>
              <p><strong>Role:</strong> {user.role}</p>
              <p><strong>Billing Rates:</strong></p>
              <ul>
                {user.rates.map((rate, index) => (
                  <li key={index}>{rate.name}: ${rate.value}</li>
                ))}
              </ul>
              <div className="mt-3">
                <button
                  className="btn btn-warning btn-sm mr-2"
                  onClick={() => handleEdit(user)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(user)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
};

export default UserManagement;
