    import React, { useEffect, useState } from 'react';
    import { useAuth } from '../context/AuthContext';
    import { useNavigate } from 'react-router-dom';
    import { useNotify } from '../context/NotificationContext';
    import Select from 'react-select';
    import { useUtils } from '../context/UtilContext';

    const Billing = () => {
        const { APP_URL } = useAuth();
        const { formatCurrency } = useUtils()
        const { error } = useNotify();
        const [clients, setClients] = useState([]);
        const [loading, setLoading] = useState(true);
        const [sortedData, setSortedData] = useState([]);
        const [searchQuery, setSearchQuery] = useState('');
        const [selectedPartner, setSelectedPartner] = useState(null);
        const [sortConfig, setSortConfig] = useState({ key: 'wipAmount', direction: 'desc' });
        const [noEntriesMessage, setNoEntriesMessage] = useState('');
        const navigate = useNavigate();

        useEffect(() => {
            fetch(`${APP_URL}/clients?total=true`, {
                credentials: 'include'
            }).then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        const formattedData = data.map(client => ({
                            id: client.id,
                            clientId: client.client_id,
                            name: client.legal_name,
                            partner: client.partner ? client.partner.username : 'N/A',
                            wipAmount: client.wip
                        }));
                        setClients(formattedData);
                        setLoading(false);
                        handleSort('wipAmount', 'desc', formattedData); // Sort by WIP on page load
                    });
                } else {
                    error(resp);
                }
            }).catch(e => error(e));
        }, []); //eslint-disable-line

        const handleSort = (key, direction = 'asc', data = clients) => {
            let sortedArray = [...data];
            sortedArray.sort((a, b) => {
                if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                return 0;
            });
            setSortedData(sortedArray);
            setSortConfig({ key, direction });
            setNoEntriesMessage(sortedArray.length === 0 ? 'No entries found' : '');
        };

        const handleSearch = () => {
            let filteredData = clients;

            if (searchQuery) {
                filteredData = filteredData.filter(client =>
                    client.wipAmount.toString().includes(searchQuery)
                );
            }

            if (selectedPartner && selectedPartner.value !== null) {
                filteredData = filteredData.filter(client =>
                    client.partner === selectedPartner.value
                );
            }

            handleSort(sortConfig.key, sortConfig.direction, filteredData);
        };

        const handleSortByWIP = () => {
            handleSort('wipAmount', 'desc'); // Sort by WIP amount descending
        };

        const partnerOptions = [
            { value: null, label: 'Select Partner' },
            ...Array.from(new Set(clients.map(client => client.partner)))
                .filter(partner => partner !== 'N/A')
                .map(partner => ({ value: partner, label: partner }))
        ];

        return (
            <div className="container mx-auto p-4 mt-8 bg-white rounded shadow">
                <h2 className="text-2xl font-bold mb-4">Billing</h2>
                <div className='mt-3 mb-2'>
                    <button
                        className="bg-green-500 text-white py-2 px-4 rounded-md"
                        onClick={handleSortByWIP}
                    >
                        Show All
                    </button>
                </div>
                <div className="mb-3 flex items-center">
                    <Select
                        options={partnerOptions}
                        value={selectedPartner || partnerOptions[0]}
                        onChange={setSelectedPartner}
                        placeholder="Select Partner"
                        className="w-1/3 mr-4"
                    />
                    <input
                        type="text"
                        className="form-control w-1/3 mr-4"
                        placeholder="Search by WIP Amount"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-md mr-4"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                </div>

                {loading ? (
                    <div className="alert alert-info w-100 mt-2 text-center" role="alert">
                        <strong>Loading...</strong>
                    </div>
                ) : (
                    <>
                        {noEntriesMessage ? (
                            <div className="p-4 bg-red-200 text-red-700 border border-red-400 rounded-md">
                                {noEntriesMessage}
                            </div>
                        ) : (
                            <div className='table-responsive'>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th
                                            className="p-2 border border-gray-300 cursor-pointer"
                                            onClick={() => handleSort('clientId', sortConfig.key === 'clientId' && sortConfig.direction === 'asc' ? 'desc' : 'asc')}
                                        >
                                            Client ID
                                        </th>
                                        <th className="p-2 border border-gray-300">Name</th>
                                        <th
                                            className="p-2 border border-gray-300 cursor-pointer"
                                            onClick={() => handleSort('partner', sortConfig.key === 'partner' && sortConfig.direction === 'asc' ? 'desc' : 'asc')}
                                        >
                                            Partner
                                        </th>
                                        <th
                                            className="p-2 border border-gray-300 cursor-pointer"
                                            onClick={() => handleSort('wipAmount', sortConfig.key === 'wipAmount' && sortConfig.direction === 'asc' ? 'desc' : 'asc')}
                                        >
                                            WIP Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedData.map((entry) => (
                                        <tr key={entry.clientId} style={{ cursor: 'pointer' }} onClick={() => navigate(`/clients/${entry.id}/wip`)} className="border-b hover:bg-gray-100">
                                            <td className="p-2 border border-gray-300">{entry.clientId}</td>
                                            <td className="p-2 border border-gray-300">{entry.name}</td>
                                            <td className="p-2 border border-gray-300">{entry.partner}</td>
                                            <td className="p-2 border border-gray-300">{formatCurrency(entry.wipAmount)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    export default Billing;
